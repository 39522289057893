import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Grid } from '../../Containers/index';
function Auth(props) {
  // const [themeMode, setThemeMode] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches);
  // const themePort = useThemePort();

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      spacing={1}>
      {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				
    <FormControlLabel
                control={
                  <Switch
									checked={themeMode}
									onClick={(e) => {
										setThemeMode(e?.target?.checked);
										themePort(e?.target?.checked)
										// DefaultThemeProvider.setMode('light')
									}}
                  />
                }
                label="Dark mode"
								/>
								</Grid> */}
      {/* <Grid item xs={0} sm={0} md={7} lg={7} xl={7}>
        <Poster />
      </Grid> */}
      <Grid item xs={12}>
        <Box autoComplete='off' sx={{ maxHeight: '100vh', overflow: 'auto' }}>
          <Outlet />
        </Box>
      </Grid>
    </Grid>
  );
}
export default Auth;
