import { Hub } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useRoutes } from 'react-router-dom';
import { handleUserLoggedIn } from '../redux/Reducer/Auth';
function Route() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('idToken')) {
      dispatch(handleUserLoggedIn(true));
    } else {
      dispatch(handleUserLoggedIn(false));
    }
    // eslint-disable-next-line
  }, []);
  const { route } = useSelector((state) => state?.auth);
  const routes = useRoutes(route);

  Hub.listen('auth', ({ payload: { event, data } }) => {
    switch (event) {
      case 'signOut':
        localStorage.clear();
        dispatch(handleUserLoggedIn(false));
        navigate('/');
        break;
      case 'oAuthSignOut':
        localStorage.clear();
        dispatch(handleUserLoggedIn(false));
        navigate('/');
        break;
      default:
        break;
    }
  });
  return <React.Fragment>{routes}</React.Fragment>;
}

export default Route;
