import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  snackbar: {
    isOpen: false,
    message: '',
    type: '',
  },
  isSideNavOpen: false,
};

let utilsSlice = createSlice({
  name: 'utilss',
  initialState: initialState,
  reducers: {
    toggleSnackBar: function (state, action) {
      // console.log('_1--------------->', initialState.snackbar);
      // console.log('_2--------------->', action.payload.isOpen);
      // console.log('_3--------------->', action.payload.message);
      // console.log('_4--------------->', action.payload.type);

      state.snackbar.isOpen = action.payload.isOpen;
      state.snackbar.message = action.payload.message;
      state.snackbar.type = action.payload.type;
      // state.snackbar = action.payload;
      // state.snackbar = { ...state.snackbar, ...action.payload };
      // console.log('_5--------------->', (state.snackbar = action.payload));
    },
    toggleSideNav: function (state, action) {
      state.isSideNavOpen = action.payload;
    },
  },
});
export const { toggleSnackBar, toggleSideNav } = utilsSlice.actions;
export default utilsSlice.reducer;
