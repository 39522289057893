import comingSoon from '../assets/Images/comingSoon.png';
import dashboard from '../assets/Images/dashboard.png';
import errorImage from '../assets/Images/error.png';
import loginBG from '../assets/Images/loginBG.png';
import logo from '../assets/Images/logo.png';
import logo_blue from "../assets/Images/logo_blue.png"
import logo_white from "../assets/Images/logo_white.png"

export const IMAGES = {
  LOGIN_BG: loginBG,
  ERROR_IMAGE: errorImage,
  LOGO: logo,
  DASHBOARD: dashboard,
  COMINGSOON: comingSoon,
  LOGO_BLUE: logo_blue,
  LOGO_WHITE: logo_white
};
