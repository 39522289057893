// import { ThemeProvider } from "@mui/material/styles";
import { Amplify } from 'aws-amplify';
import { Suspense, useEffect } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SnackBar } from './app/Containers';
import DefaultThemeProvider from './context/ThemeProvider';
import ViewportProvider from './context/ViewPortProvider';
import store from './redux/Store';
import Route from './routes';
import Loader from './utils/SuspenseLoader';

Amplify.configure({
  Auth: {
    identityPoolId: `${process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID}`,
    region: `${process.env.REACT_APP_COGNITO_REGION}`,
    identityPoolRegion: `${process.env.REACT_APP_COGNITO_REGION}`,
    userPoolId: `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,
    userPoolWebClientId: `${process.env.REACT_APP_COGNITO_CLIENT_ID}`,
    // userPoolWebClientId: `${process.env.REACT_APP_COGNITO_CLIENT_ID}`,
    // oauth: {
    //   domain: `${import.meta.env.VITE_COGNITO_DOMAIN}`,
    //   scope: ["email", "openid"],
    //   redirectSignIn: `${import.meta.env.VITE_SOCIAL_LOGIN_REDIRECT}`,
    //   redirectSignOut: `${import.meta.env.VITE_SOCIAL_LOGOUT_REDIRECT}`,
    //   responseType: "code",
    // },
  },
});
function App() {
  useEffect(() => {
    clarity.init(`${process.env.REACT_APP_MICROSOFT_CLARITY_ID}`);
  }, []);
  return (
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <SnackBar />
        <DefaultThemeProvider>
          <ViewportProvider>
            <BrowserRouter>
              <Route />
            </BrowserRouter>
          </ViewportProvider>
        </DefaultThemeProvider>
      </Provider>
    </Suspense>
  );
}

export default App;
