import { Visibility, VisibilityOff } from "@mui/icons-material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Card from "@mui/material/Card";
import { useTheme } from "@mui/material/styles";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QRCode } from "react-qrcode-logo";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "../../../../../constants/ImageConstants";
import { ColorModeContext } from "../../../../../context/ThemeProvider";
import { viewVerifiableCredential } from "../../../../../services/PasswrodProtectedServices";
import { PasswordProtectedSchema } from "../../../../../utils/ValidationSchema";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  makeStyles,
} from "../../../../Containers/index";
import { HeaderAppBar } from "../../../Authenticated/Layout/Settings";
// Table Data Fields --> (SFE Certificate (s))  Verified Information certificate name and URL value split separately
const handleSplit = (val) => {
  // This pattern (:) and (,) seperated value check
  const pattern = /([^:]+): (https:\/\/[^,]+),?/g;
  const matches = [...val.matchAll(pattern)];
  const results = matches.map((match) => ({
    title: match[1].trim(),
    url: match[2].trim(),
  }));
  if (val === "N.A" || val === "") {
    return val;
  } else {
    const htmlLinks = results.map((result) => (
      <>
        <span>{result.title}:</span>
        <br />
        <a href={result.url} target="_blank" rel="noreferrer">
          {result.url}
        </a>
        <br />
      </>
    ));
    return htmlLinks;
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100vh",
    overflow: "hidden",
  },
  a4Card: {
    // padding: theme.spacing(5),
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  gridItem: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}));

function PasswordProtected() {
  const theme = useTheme();
  const classes = useStyles();
  const { toggleColorMode } = useContext(ColorModeContext);
  const [mode, setMode] = useState("light");
  // Screen width
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // Update screenWidth when the window is resized
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Attach the resize event listener when the component mounts
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      setMode("dark");
    }
  }, []);

  const handleToggleColorMode = () => {
    if (mode === "light") {
      setMode("dark");
    } else {
      setMode("light");
    }
    toggleColorMode();
  };
  const isDarkMode = theme.palette.mode === "dark";
  // Issued on date
  const [issuedDate, setIssuedDate] = useState("");
  // Issuer Description
  const [issuerDescription, setIssuerDescription] = useState("");
  // Issuer Name
  const [issuerName, setIssuerName] = useState("");
  // Issuer Name
  const [issuedTo, setIssuedTo] = useState("");
  // QR Code Link
  const [qrCodeValue, setQrCodeValue] = useState("");
  // URL value get by split
  const urlValue = window.location.href;
  const segments = urlValue.split("/");
  const lastSegment = segments[segments.length - 1];
  // Loader true or false check
  const [isLoading, setIsLoading] = useState(false);
  // basicBusinessInformation table value stored
  let [basicBusinessInformation, setBasicBusinessInformation] = useState([]);
  // Filter  basicBusinessInformation without empty value and not a (N.A) string text and undefined and null
  const filteredBasicBusinessInformation = basicBusinessInformation.filter(
    (item) =>
      item.value !== "" &&
      item.ts !== "" &&
      item.lv !== "" &&
      item.value !== "N.A" &&
      item.value !== null &&
      item.ts !== null &&
      item.lv !== null &&
      item.value !== undefined &&
      item.ts !== undefined &&
      item.lv !== undefined
  );
  // Error page display
  const [errorPage, setErrorPage] = useState(false);
  // Password show and hide
  const [showPassword, setShowPassword] = useState(false);

  // --------------------------------Change Hypen to Underscore to all keys value(- to _)----------------
  const transformKeys = (object) => {
    const transformedObject = {};
    for (let key in object) {
      const newKey = key.replace(/-/g, "_"); // Replace hyphens with underscores
      transformedObject[newKey] = object[key];
    }
    setIssuedTo(transformedObject?.Company_Legal_Name);
    showData(transformedObject);
  };

  const { isOpen, type, message } = useSelector(
    (state) => state?.utils?.snackbar
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tableHeaderColor = "#17236E";
  const tableHeaderTextColor = "#FFFFFF";
  const margingTop = 1;
  const tableMinWidth = 650;

  // formateDate Function
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const formattedTime = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const time = `${formattedHours}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")} ${formattedTime}`;
    if (inputDate === "N.A") {
      return inputDate;
    } else {
      return `${year}-${month}-${day} ${time}`;
    }
  };

  // formate issued on date:
  const formateIssuedOnDate = (getInputDate) => {
    const date = new Date(getInputDate);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };
  // ----------------------------showData function ----------------------------------

  const showData = (jsonObject) => {
    setBasicBusinessInformation([
      {
        name: "Company Legal Name",
        value: jsonObject?.Company_Legal_Name,
        ts: jsonObject?.Company_Legal_Name_Trusted_Source,
        lv: jsonObject?.Company_Legal_Name_Last_Verified,
      },
      {
        name: "Tax Identification Number",
        value: jsonObject?.Tax_Identification_Number,
        ts: jsonObject?.Tax_Identification_Number_Trusted_Source,
        lv: jsonObject?.Tax_Identification_Number_Last_Verified,
      },
      {
        name: "Entity Registration Number",
        value: jsonObject?.Entity_Registration_Number,
        ts: jsonObject?.Entity_Registration_Number_Trusted_Source,
        lv: jsonObject?.Entity_Registration_Number_Last_Verified,
      },
      {
        name: "Date of Incorporation/Registration",
        value: jsonObject?.Date_of_Incorporation_or_Registration,
        ts: jsonObject?.Date_of_Incorporation_or_Registration_Trusted_Source,
        lv: jsonObject?.Date_of_Incorporation_or_Registration_Last_Verified,
      },
      {
        name: "Country of Incorporation/Registration ",
        value: jsonObject?.Country_of_Incorporation_or_Registration,
        ts: jsonObject?.Country_of_Incorporation_or_Registration_Trusted_Source,
        lv: jsonObject?.Country_of_Incorporation_or_Registration_Last_Verified,
      },
      {
        name: "Registered Address",
        value: jsonObject?.Registered_Address,
        ts: jsonObject?.Registered_Address_Trusted_Source,
        lv: jsonObject?.Registered_Address_Last_Verified,
      },
      {
        name: "Nature of Business",
        value: jsonObject?.Nature_of_Business,
        ts: jsonObject?.Nature_of_Business_Trusted_Source,
        lv: jsonObject?.Nature_of_Business_Last_Verified,
      },
      {
        name: "Industry",
        value: jsonObject?.Industry,
        ts: jsonObject?.Industry_Trusted_Source,
        lv: jsonObject?.Industry_Last_Verified,
      },
      {
        name: "SME Primary Contact Person First Name",
        value: jsonObject?.SME_Primary_Contact_Person_First_Name,
        ts: jsonObject?.SME_Primary_Contact_Person_First_Name_Trusted_Source,
        lv: jsonObject?.SME_Primary_Contact_Person_First_Name_Last_Verified,
      },
      {
        name: "SME Primary Contact Person Last Name",
        value: jsonObject?.SME_Primary_Contact_Person_Last_Name,
        ts: jsonObject?.SME_Primary_Contact_Person_Last_Name_Trusted_Source,
        lv: jsonObject?.SME_Primary_Contact_Person_Last_Name_Last_Verified,
      },
      {
        name: "Primary Email Address",
        value: (
          <a href={`mailto:${jsonObject?.Primary_Email_Address}`}>
            {jsonObject?.Primary_Email_Address}
          </a>
        ),
        ts: jsonObject?.Primary_Email_Address_Trusted_Source,
        lv: jsonObject?.Primary_Email_Address_Last_Verified,
      },
      {
        name: "Contact Phone Number",
        value: (
          <a href={`tel:${jsonObject?.Contact_Phone_Number}`}>
            {jsonObject?.Contact_Phone_Number}
          </a>
        ),
        ts: jsonObject?.Contact_Phone_Number_Trusted_Source,
        lv: jsonObject?.Contact_Phone_Number_Last_Verified,
      },
      {
        name: "CBG customer",
        value: jsonObject?.CBG_customer,
        ts: jsonObject?.CBG_customer_Trusted_Source,
        lv: jsonObject?.CBG_customer_Last_Verified,
      },

      {
        name: "SFE Certificate (s)",
        value: handleSplit(jsonObject?.SFE_Certificates),
        ts: jsonObject?.SFE_Certificates_Trusted_Source,
        lv: jsonObject?.SFE_Certificates_Last_Verified,
      },

      {
        name: "Ghanapost GPS",
        value: jsonObject?.Ghanapost_GPS,
        ts: jsonObject?.Ghanapost_GPS_Trusted_Source,
        lv: jsonObject?.Ghanapost_GPS_Last_Verified,
      },
    ]);
  };

  const [responseFlag, setResponseFlag] = useState(false);

  // -------Formik handleSubmit (InitialValue, validationSchema, onSubmit)-------

  const formik = useFormik({
    initialValues: {
      credential_id: lastSegment,
      password: "",
      view_as_html: false,
    },
    validationSchema: PasswordProtectedSchema,
    onSubmit: async (values) => {
      setIsLoading(true);

      try {
        var res = await viewVerifiableCredential(values);
        if (res.data.status === "success") {
          transformKeys(res?.data?.data?.credential_subject);
          setResponseFlag(true);
          setIssuedDate(res?.data?.data?.issuance_date);
          setIssuerDescription(res?.data?.data?.issuer?.description);
          setIssuerName(res?.data?.data?.issuer?.name);
          setQrCodeValue(res?.data?.data?.qr_data);
        }
      } catch (e) {
        setErrorPage(true);
      } finally {
        setIsLoading(false);
      }
    },
  });
  return (
    <Box className={classes.root}>
      {/* ----------------------------------AppBar------------------------------- */}
      <HeaderAppBar
        className="HeaderAppBar"
        // position='sticky'
        position="fixed"
        sx={{
          bgcolor: theme.palette.background.default,
          justifyContent: "center",
          boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
          mb: 4,
        }}
      >
        <Toolbar>
          <Box
            sx={{
              flexGrow: 1,
              pl: 1,
              cursor: "pointer",
            }}
          >
            <img
              src={isDarkMode ? IMAGES.LOGO_WHITE : IMAGES.LOGO_BLUE}
              height="48"
              width="48"
              style={{ aspectRatio: "3.6428/1" }}
              alt="logo"
            />
          </Box>{" "}
          <Box sx={{ ml: "auto" }}>
            <IconButton
              aria-label="Switch Theme"
              onClick={() => handleToggleColorMode()}
            >
              {mode === "light" ? <Brightness4Icon /> : <Brightness7Icon />}
            </IconButton>
          </Box>
        </Toolbar>
      </HeaderAppBar>
      <Container maxWidth="lg" sx={{ mt: 15, mb: 4, p: "20px" }}>
        <Grid container spacing={2} sx={{ pl: 0, pr: 2, ml: 0 }}>
          {!responseFlag ? (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
                textAlign: "center",
                flexDirection: "column",
                gap: "10px",
                px: 2,
              }}
            >
              <Box>
                <Typography
                  variant="h4"
                  style={{ color: theme.palette.text.primary }}
                >
                  {errorPage ? "Something went wrong" : "Password Protected"}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  style={{ color: theme.palette.text.primary }}
                >
                  {errorPage
                    ? "Invalid credential password"
                    : "Please enter the password created when this credential was issued"}
                </Typography>
              </Box>
              {errorPage ? (
                ""
              ) : (
                <Box sx={{}}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      sx={{ width: "300px" }}
                      type={showPassword ? "text" : "password"}
                      label=""
                      name="password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Show or Hide Password"
                              edge="end"
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      value={formik.values.password
                        .trimStart()
                        .replace(/\s/g, "")
                        .replace(/\p{Emoji_Presentation}/gu, "")}
                      placeholder="Enter the password"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      helperText={
                        formik.touched.password && t(formik.errors.password)
                      }
                    />
                  </FormControl>
                </Box>
              )}
              <Box>
                <Button
                  variant="contained"
                  sx={{ textTransform: "none", width: "300px", mt: "16px" }}
                  onClick={() => {
                    if (errorPage) {
                      setErrorPage(false);
                    } else {
                      formik.handleSubmit();
                    }
                  }}
                >
                  {errorPage ? "Back" : "View Credential"}
                </Button>
              </Box>
            </Grid>
          ) : (
            <Card className={classes.a4Card} sx={{ my: 4 }}>
              <Box sx={{ p: 5 }}>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <img
                    src={isDarkMode ? IMAGES.LOGO_WHITE : IMAGES.LOGO_BLUE}
                    height="48"
                    width="48"
                    style={{ aspectRatio: "3.6428/1" }}
                    alt="logo"
                  />
                  <Typography
                    style={{ color: theme.palette.text.primary }}
                    sx={{ mt: 2, fontSize: "26px", fontWeight: "bold" }}
                  >
                    {issuerName}
                  </Typography>
                  <Typography
                    sx={{ mt: 2, fontSize: "18px" }}
                    style={{ color: theme.palette.text.primary }}
                  >
                    has issued a Verifiable Credential to
                  </Typography>
                  <Typography
                    style={{ color: theme.palette.text.primary }}
                    sx={{ mt: 3, fontSize: "48px", fontWeight: "bold" }}
                  >
                    {issuedTo}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  {/* ---------------------Basic Business Information Table---------------------- */}
                  <Box>
                    <TableContainer
                      sx={{
                        mt: margingTop,
                      }}
                    >
                      <Table
                        sx={{
                          minWidth: tableMinWidth,
                        }}
                      >
                        <TableHead
                          sx={{
                            backgroundColor: tableHeaderColor,
                          }}
                        >
                          <TableRow>
                            <TableCell
                              sx={{
                                color: tableHeaderTextColor,
                              }}
                            >
                              Data Fields
                            </TableCell>

                            <TableCell
                              sx={{
                                color: tableHeaderTextColor,
                              }}
                            >
                              Verified Information
                            </TableCell>

                            <TableCell sx={{ color: tableHeaderTextColor }}>
                              Trusted Source
                            </TableCell>
                            <TableCell sx={{ color: tableHeaderTextColor }}>
                              Last Verified
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredBasicBusinessInformation.map((row) => (
                            <TableRow
                              sx={{
                                "&:nth-of-type(even)": {
                                  backgroundColor:
                                    theme.palette.background.oddRow,
                                },
                              }}
                              key={row.name}
                            >
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell>{row.value}</TableCell>
                              <TableCell>{row.ts}</TableCell>
                              <TableCell>{formatDate(row.lv)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
                <Grid container>
                  <Grid item xs={7} sm={6}>
                    <Box sx={{ display: "flex", mt: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mr: 2,
                        }}
                      >
                        <img
                          src={
                            isDarkMode ? IMAGES.LOGO_WHITE : IMAGES.LOGO_BLUE
                          }
                          height="38"
                          width="38"
                          style={{ aspectRatio: "3.6428/1" }}
                          alt="logo"
                        />
                        <Box sx={{ ml: 4, my: 2 }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Verifiable Credential
                          </Typography>
                          <Typography>
                            Issued on: {formateIssuedOnDate(issuedDate)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={5} sm={6}>
                    {" "}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        mt: 2,
                      }}
                    >
                      <QRCode
                        fgColor={theme.palette.primary.main}
                        bgColor="transparent"
                        qrStyle="dots"
                        logoPadding={1}
                        value={qrCodeValue}
                        size={
                          screenWidth > 600
                            ? Math.min(window.innerWidth * 0.8, 150)
                            : Math.min(window.innerWidth * 0.8, 90)
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          )}
        </Grid>
        <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </Box>
  );
}

export default PasswordProtected;
