
import { createTheme } from '@mui/material/styles';

export const themeLight = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: 'hsl(0, 0%, 100%)',
      paper: 'hsl(0, 0%, 100%)',
      oddRow: 'hsl(0, 0%, 95%)'   // Add this line for a slightly off-white color for odd rows
    },
    primary: {
      main: '#17236E',
    },
    secondary: {
      main: '#000000',
    },
    error: {
      main: '#c32e31',
    },
  },
  spacing: 4,
  typography: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
});

export const themeDark = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',  // Set a darker background color
      paper: '#1e1e1e',    // Set paper background to a slightly lighter shade
      oddRow: '#1c1c1c'
    },
    fill: {
      default: '#000',
    },
    primary: {
      main: '#ffff',
    },
    secondary: {
      main: '#000000',
    },
  },
  spacing: 4,
  typography: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
});
