import PasswordProtected from '../../app/Pages/Auth/Components/PasswordProtected';
import Index from '../../app/Pages/Auth/index';

export const PublicRouteConfig = [
  {
    path: '',
    element: <Index />,
    children: [
      {
        path: '/',
        element: <PasswordProtected />,
      },
      // {
      //   path: ROUTE.PASSWORDPROTECTED,
      //   element: <PasswordProtected />,
      // },
      // {
      //   path: ROUTE.LOGIN,
      //   element: <Login />,
      // },
      // {
      //   path: ROUTE.SIGN_UP,
      //   element: <SignUp />,
      // },
      // {
      //   path: ROUTE.FORGET_PASSWORD,
      //   element: <ForgetPassword />,
      // },
      // {
      //   path: ROUTE.VERIFY_EMAIL,
      //   element: <EmailVerify />,
      // },
      // {
      //   path: ROUTE.RESET_PASSWORD,
      //   element: <ResetPassword />,
      // },
    ],
  },
  // {
  //   path: '/review_sign_up_request',
  //   element: <ReviewRequest />,
  // },
  {
    path: '*',
    element: <PasswordProtected />,
  },
];
