import PasswordProtected from '../../app/Pages/Auth/Components/PasswordProtected';
export const PrivateRouteConfig = [
  {
    path: '/',
    element: <PasswordProtected />,
  },
  {
    path: '*',
    element: <PasswordProtected />,
  },
  // {
  //   path: '',
  //   element: <AppLayout />,
  //   children: [
  //     {
  //       path: ROUTE.ROOT,
  //       element: <DashBoard />,
  //     },
  //     // {
  //     //   path: ROUTE.SFE,
  //     //   element: <Sfe />,
  //     // },
  //     // {
  //     //   path: ROUTE.DEV_DASHBOARD,
  //     //   element: <DevDashboard />,
  //     // },
  //     // {
  //     //   path: ROUTE.SETTINGS,
  //     //   element: <Settings />,
  //     // },
  //     // {
  //     //   path: ROUTE.TRUSTED,
  //     //   element: <TrustedCredentials />,
  //     // },
  //   ],
  // },
  // {
  //   path: '*',
  //   element: <Error404 />,
  // },
];
